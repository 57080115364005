import React, { useState, useEffect } from 'react';
import { auth, googleProvider, isPasswordValid } from '../firebase';
import LoadingCircle from '../components/LoadingCircle';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification, signInWithRedirect, signInWithPopup  } from 'firebase/auth';
import { firestore, doc, setDoc } from '../firebase';



function SignUpBox({ onGoBack, onSignUpSuccess, closeAllPopups }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [Neutralmessage, setNeutralMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [loadingDots, setLoadingDots] = useState(0); // Added loadingDots state
  const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
  const [isPasswordSpecialCharValid, setIsPasswordSpecialCharValid] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const clearError = () => {  
    setError(null);
  };


const checkEmailVerification = () => {
const checkForVerifiedInterval = setInterval(() => {
  if (auth.currentUser) {
    auth.currentUser
      .reload()
      .then(() => {
        if (auth.currentUser.emailVerified) {
          clearInterval(checkForVerifiedInterval);
          setIsEmailVerified(true);
          setVerificationMessage('Email successfully verified. Welcome on board! This box will now close.');
          setNeutralMessage(''); // Clear the neutral message

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setNeutralMessage('Verification email sent. Please check your inbox and verify your email.');
        }
      })
      .catch((error) => {
        console.error('Error reloading user:', error);
      });
  } else {
    clearInterval(checkForVerifiedInterval);
  }
}, 2000); // Check every 2 seconds

return () => clearInterval(checkForVerifiedInterval);
};

  
  

  // Start the loading dots animation when loading is true
  useEffect(() => {
    let dotInterval;

    if (loading) {
      dotInterval = setInterval(() => {
        setLoadingDots((prevDots) => (prevDots + 1) % 4);
      }, 500);
    } else {
      clearInterval(dotInterval);
      setLoadingDots(0);
    }

    return () => {
      clearInterval(dotInterval);
    };
  }, [loading]);


  const handleSignUp = () => {
    clearError();
    setLoading(true);
  
    if (!firstName || !lastName || !email || !password) {
      setError('Please fill in all fields.');
      setLoading(false);
      return;
    }
  
    // Check if the password meets your custom criteria
    if (!isPasswordValid(password)) {
      setError('Password does not meet the required criteria.');
      setLoading(false);
      return;
    }
  

  

    const profileImageURLs = [
      'https://firebasestorage.googleapis.com/v0/b/tioassist.firebasestorage.app/o/profileimages%2Ftio-fire.webp?alt=media&token=56412046-37c9-42d7-91c7-9414153fe156',

      'https://firebasestorage.googleapis.com/v0/b/tioassist.firebasestorage.app/o/profileimages%2Ftio-green.webp?alt=media&token=5b47ab88-da25-4c13-b01d-78ed0f2ff023',

      'https://firebasestorage.googleapis.com/v0/b/tioassist.firebasestorage.app/o/profileimages%2Ftio-greenish.webp?alt=media&token=9faf7c99-7566-45cc-b561-b0c9cb928b7c',

      'https://firebasestorage.googleapis.com/v0/b/tioassist.firebasestorage.app/o/profileimages%2Ftio-pinkish.webp?alt=media&token=e72f7bd5-09ca-485a-ba5a-d8bc72d8b266',

      'https://firebasestorage.googleapis.com/v0/b/tioassist.firebasestorage.app/o/profileimages%2Ftio-purple.webp?alt=media&token=88c35760-2a75-44ff-97db-5d40ea3b6137',
      
      'https://firebasestorage.googleapis.com/v0/b/tioassist.firebasestorage.app/o/profileimages%2Ftio-red.webp?alt=media&token=c5b8d4c6-da7b-4029-9b64-bff33fb1e5fb',
      
      'https://firebasestorage.googleapis.com/v0/b/tioassist.firebasestorage.app/o/profileimages%2Ftio-regal.webp?alt=media&token=291d568a-c69f-411f-ae7d-32e3a3086ab6',
      
      'https://firebasestorage.googleapis.com/v0/b/tioassist.firebasestorage.app/o/profileimages%2Ftio-sea.webp?alt=media&token=89daaf1b-6d1e-4153-a729-2c7e960f1fdb',
      
      'https://firebasestorage.googleapis.com/v0/b/tioassist.firebasestorage.app/o/profileimages%2Ftio-space.webp?alt=media&token=c0e79d12-0ba4-4947-b22c-7e1792c4c679',
      
    
    ]
    
    
    const randomIndex = Math.floor(Math.random() * profileImageURLs.length);
    const selectedProfileImageURL = profileImageURLs[randomIndex];

  
  
    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      const fullName = `${firstName} ${lastName}`;
      const selectedProfileImageURL = profileImageURLs[Math.floor(Math.random() * profileImageURLs.length)];

        // Set the user's display name and photoURL
        updateProfile(user, {
          displayName: fullName,
          photoURL: selectedProfileImageURL,
        })
        .then(() => {
          // Update Firestore document
          const userDocRef = doc(firestore, 'userProfiles', user.uid);
          return setDoc(userDocRef, {
            firstName: firstName,
            lastName: lastName,
            email: email,
            photoURL: selectedProfileImageURL
          }, { merge: true });
        })
        .then(() => {
          return sendEmailVerification(user);
        })
          .then(() => {
            sendEmailVerification(user)
            .then(() => {
              setLoading(false);
              setNeutralMessage('Verification email sent. Please check your inbox and verify your email.');
              setVerificationMessage(''); // Clear any previous verification message
              setError(null);
              setIsEmailSent(true); // Set this new state to true
              checkEmailVerification();
            })
            .catch((error) => {
              console.error('Error sending verification email:', error);
              setError('An error occurred while sending the verification email.');
              setLoading(false);
            });
          })
          .catch((error) => {
            console.error('Error updating profile:', error);
            setError('An error occurred during profile update');
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error('Error creating user:', error);
        setError('An error occurred during sign-up.');
        setLoading(false);
        setVerificationMessage(''); // Clear any previous success message
        if (error.code === 'auth/email-already-in-use') {
          setError('Email is already in use. Please use a different email address.');
        } else {
          setError('An error occurred during sign-up.');
        }
      });
  };
  
    

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSignUp();
  
      const submitButton = document.getElementById('btnSignup');
      if (submitButton) {
        submitButton.focus();
      }
    }
  };

  const capitalizeFirstLetter = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
  };

  useEffect(() => {
    if (auth.currentUser) {
      checkEmailVerification();
    }
  }, [onSignUpSuccess]);


  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    // Initial check
    checkIsMobile();

    // Add event listener for window resize to update isMobile
    window.addEventListener('resize', checkIsMobile);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

const signInWithGoogle = async () => {
  try {
    const isIPhoneOrAndroid = /iPhone|Android/i.test(navigator.userAgent);
    const isEdgeOrFirefox = /Edge|Firefox/i.test(navigator.userAgent);

    if (isIPhoneOrAndroid || isEdgeOrFirefox) {
      // For mobile devices, Edge, or Firefox: Use popup
      const signInPromise = signInWithPopup(auth, googleProvider);

      // Wait for the sign-in to complete
      const userCredential = await signInPromise;
      const user = userCredential.user;
      const firstName = user.displayName.split(' ')[0];
      console.log('Signed in successfully with Google using popup:', firstName);

      // Refresh the page after a short delay
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      // For other desktop browsers: Use redirect
      // Initiate the redirect
      await signInWithRedirect(auth, googleProvider);
      
      // The page will automatically reload after redirect, so we don't need to manually refresh
    }

    // Close the login box
    if (onLoginSuccess) {
      onLoginSuccess();
    }
  } catch (err) {
    console.error('Google sign-in error:', err);
    // Optionally handle the error (e.g., show an error message)
  }
};
  /*
  const signInWithFacebook = async () => {
    try {
      // Delay before closing the box
      setTimeout(() => {
        if (onLoginSuccess) {
          onLoginSuccess();
        }
      }, 100); // Adjust the delay duration (500ms in this example)
  
      await signInWithRedirect(auth, facebookProvider);
    } catch (err) {
      console.error(err);
    }
  };
*/

  const resetAllStates = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setError(null);
    setIsEmailVerified(false);
    setVerificationMessage('');
    setNeutralMessage('');
    setLoading(false);
    setIsPasswordLengthValid(false);
    setIsPasswordSpecialCharValid(false);
    setIsPasswordFocused(false);
    setIsEmailSent(false);
  };

  return (
    
    <div className="login-box" id="loginBox">
      <div >
        

      <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="black"
            strokeWidth={1.5}
            onClick={() => {
              resetAllStates();
              onGoBack();
            }}
            stroke="currentColor"
            className="image-go-back-svg"
            viewBox="0 -960 960 960">
            <path d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z"/>
              </svg>
    
      </div>
  <div className='login-container'>
    <div className="login-content">
  
  <div className="login-image">

  <img src={`/images/Tios/Earning-Tio.webp`} alt="TIO illustration" className="img-fluid-tio" />

  </div>

    <div className='login-context'>
  <div className="log-in-text">
        <h4 className="h3 mb-3">Sign up</h4>
      </div>
  {isMobile && (
<img
src={`/images/logo/Logo-dark.webp`}
alt="TioAssist logo"
width="100"
style={{
  position: 'absolute',
  top: 15,
  left: 10,
}}
/>
)}

  <div className="social-login">
<button id="google-login" className="social-button google" onClick={signInWithGoogle} type="button">
  <span className="text" >Sign up with Google</span>
  <span className="icon">
  {/* Google icon */}
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="35" width="35">
  <path fill="#4285f4" d="M386 400c45-42 65-112 53-179H260v74h102c-4 24-18 44-38 57z"></path>
  <path fill="#34a853" d="M90 341a192 192 0 0 0 296 59l-62-48c-53 35-141 22-171-60z"></path>
  <path fill="#fbbc02" d="M153 292c-8-25-8-48 0-73l-63-49c-23 46-30 111 0 171z"></path>
  <path fill="#ea4335" d="M153 219c22-69 116-109 179-50l55-54c-78-75-230-72-297 55z"></path>
  </svg>
  </span>
  </button>

{/*
  <button id="facebook-login" className="social-button facebook" onClick={signInWithFacebook} type="button">
    <span className="icon">
      <svg fill="none" height="35" viewBox="0 0 24 24" width="35" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.5 12.058c0-5.28-4.253-9.558-9.5-9.558s-9.5 4.279-9.5 9.558c0 4.771 3.473 8.725 8.016 9.442v-6.68H8.104v-2.762h2.412V9.952c0-2.395 1.417-3.718 3.588-3.718 1.04 0 2.126.186 2.126.186v2.352h-1.198c-1.18 0-1.548.738-1.548 1.494v1.792h2.635l-.421 2.763h-2.214V21.5c4.543-.717 8.016-4.67 8.016-9.442z" fill="#3b5998"></path>
      </svg> 
    </span>
    <span className="text">Sign up with Facebook</span>
  </button>
*/}
  
<div className="divider">
    <span className="divider-text">OR</span>
  </div>

        <form id="signupForm">
          <div className="signup-form ">
          <div className="col-lg-12 col-md mb-3 heightfont">
            <input
              type="text"
              placeholder="First Name"
              id="input-FirstName"
              className="  form-control form-control-lg borderradius "
              value={firstName}
              onChange={(e) => setFirstName(capitalizeFirstLetter(e.target.value))} // Capture user's input for first name and capitalize
              onKeyDown={handleInputKeyDown} // Submit on Enter
            />
          </div>
          <div className="col-lg-12 col-md mb-3 heightfont">
            <input
              type="text"
              placeholder="Last Name"
              id="input-LastName"
              className=" form-control form-control-lg borderradius"
              value={lastName}
              onChange={(e) => setLastName(capitalizeFirstLetter(e.target.value))} // Capture user's input for last name and capitalize
              onKeyDown={handleInputKeyDown} // Submit on Enter
      
            />
          </div>
            <div className="col-lg-12 mb-3 heightfont">
              <input
                type="email"
                placeholder="email address"
                id="input-Email"
                className=" form-control form-control-lg borderradius"
                autoComplete="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(null);
                  setError(null);
                }}
                onKeyDown={handleInputKeyDown} // Submit on Enter
              />
            </div>
            <div className="col-lg-12 ">
            <input
  type="password"
  placeholder="password"
  id="input-Password"
  className="form-control form-control-lg borderradius"
  autoComplete="new-password"
  value={password}
  onChange={(e) => {
    const newPassword = e.target.value;

    const hasLowerCase = /[a-z]/.test(newPassword);
    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasDigit = /\d/.test(newPassword);
    const hasSpecialChar = /[@$!%*?&]/.test(newPassword);
    setPasswordError(null);
    setError(null);
    // Check password length
    setIsPasswordLengthValid(newPassword.length >= 8);

    // Check password special characters
    setIsPasswordSpecialCharValid(hasLowerCase && hasUpperCase && hasDigit && hasSpecialChar);

    // Update password state
    setPassword(newPassword);

    // Clear the error message if it's related to password criteria
    if (error === 'Password does not meet the required criteria.') {
      setError(null);
    }
  }}
  onKeyDown={handleInputKeyDown}
  onFocus={() => setIsPasswordFocused(true)}
/>
            </div>
            

{/* Display password requirements */}
{!loading && !error && !verificationMessage && isPasswordFocused && !isEmailVerified && !isEmailSent && (
  <div className="password-indicators mt-1">
    {!isPasswordLengthValid && (
      <span className="indicator-text error-text">Password must be at least 8 characters</span>
    )}
    {isPasswordLengthValid && !isPasswordSpecialCharValid && (
      <span className="indicator-text error-text">
        Password must contain at least one special character, number, lowercase and uppercase
      </span>
    )}
    {isPasswordLengthValid && isPasswordSpecialCharValid && (
      <span className="indicator-text text-success">Password meets all requirements</span>
    )}
  </div>
)}


{!verificationMessage && Neutralmessage && (
  <p style={{ margin: '5px 0' }} className="neutral-message">{Neutralmessage}</p>
)}

{verificationMessage && (
  <div style={{ color: 'green' }} className="verification-message success-text">
    {verificationMessage}
  </div>
)}

{error && <div className="error-message error-text">{error}</div>}

<div className="col-lg-12 col-md heightfont">
  {!isEmailVerified && !isEmailSent && !loading && (
    <button type="button" className="signup-button nohover" onClick={handleSignUp} id="btnSignup">
      Submit
    </button>
  )}
  {loading && (
<div className="loading-wrapper-center">
  <LoadingCircle />
</div>
)}

</div>
          </div>
        </form>
    </div>
    <div className="terms-container">
    <div role="separator" className="terms-divider">
<div className="terms">
<p className="fw-light" style={{ fontSize: '14px' }}>
  By continuing, you agree to our <a href="URL_TO_TERMS_OF_USE" target="_blank" rel="noopener">Terms of Use</a>.
  Read our <a href="URL_TO_PRIVACY_POLICY" target="_blank" rel="noopener">Privacy Policy</a>.
</p>
</div>
</div>
</div>
      </div>
      
    </div>

  </div>
</div>
  );
}

export default SignUpBox;